import { ref, watch } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useBots } from '@/store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// shared
import { PRESET_FACTORY } from '@shared/factories';

export default function (props, context) {
    // store
    const gl = useGl();
    const bots = useBots();

    // i18n
    const { t } = useI18n();

    // vars
    const currPresetName = ref();
    watch(() => bots.currentPreset, v => {
        if (v) {
            currPresetName.value = v;
            bots.currentPreset = null;
        }
    });

    const presetChanged = presetName => {
        if (!presetName) {
            currPresetName.value = null;
            bots.dataForm.settings = PRESET_FACTORY({ profit_coin: bots.dataForm.settings.profit_coin });
        } else {
            currPresetName.value = presetName;
            const preset = bots.presets[currPresetName.value]?.settings || {};

            bots.dataForm.settings = PRESET_FACTORY({ ...bots.dataForm.settings, ...preset });
        }
    };

    return {
        gl,
        bots,
        currPresetName,
        t,
        presetChanged,
    };
}