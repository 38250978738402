<template>
<div>
    <n-tabs type="line" v-model:value="currTab">
        <n-tab-pane name="rbpsSettings" :tab="bots.localization['bot_auto_switch_volatility_analyzer_settings_tab_title']">
            <div class="flex mt-4">
                <div class="flex">
                <div class="flex items-center">
                    <div class="text-base whitespace-nowrap">{{ bots.localization['bot_auto_switch_type_volatility_analyzer_exchange'] }}</div>
                    <n-popover
                        trigger="hover"
                        placement="top"
                        class="rounded-md bg-main">
                        <template #trigger>
                            <n-icon class="text-main cursor-pointer text-xl mx-2">
                                <QuestionCircle20Filled />
                            </n-icon>
                        </template>
                        <span class="text-xs text-white" v-html="bots.help['switch_exchange']"></span>
                    </n-popover>
                </div>
                <span
                    class="inline-block rb-td-stock-icon mx-4"
                    :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`" >
                </span>
                </div>
                <n-divider vertical class="h-auto" />
                <div class="flex ml-4">
                    <div class="flex items-center">
                        <div class="text-base whitespace-nowrap">{{ bots.localization['bot_auto_switch_type_volatility_analyzer_market'] }}</div>
                        <n-popover
                            trigger="hover"
                            placement="top"
                            class="max-width-[500px] rounded-md bg-main">
                            <template #trigger>
                                <n-icon class="text-main cursor-pointer text-xl mx-2">
                                    <QuestionCircle20Filled />
                                </n-icon>
                            </template>
                            <span class="text-xs text-white" v-html="bots.help['switch_market']"></span>
                        </n-popover>
                    </div>
                    <div class="flex items-center">
                        <rb-coin class="w-6" :coin="currency(dataForm.settings.profit_coin)" />
                        <div class="font-semibold ml-4">{{ currency(dataForm.settings.profit_coin).title }}</div>
                    </div>
                </div>
            </div>
            <n-divider />

            <rb-switch
                largeHelp
                :label="bots.localization['bot_auto_switch_type_volatility_analyzer_black_white_lists']"
                :help="bots.help['switch_use_bw_list']"
                v-model:value="dataForm.switch_tp.use_bw_list" />
            <n-divider />

            <template v-if="symbolsForMarketRefsReady && dataForm.switch_tp.use_bw_list">
                <div class="flex items-start">
                    <rb-switch
                        class="w-3/12 pr-2"
                        :label="bots.localization['bot_auto_switch_type_volatility_analyzer_set_white_list']"
                        :value="dataForm.switch_tp.is_white_list"
                        @update:value="dataForm.switch_tp.is_white_list = $event"/>

                    <rb-switch
                        class="w-3/12 px-2"
                        :label="bots.localization['bot_auto_switch_type_volatility_analyzer_set_black_list']"
                        :value="!dataForm.switch_tp.is_white_list"
                        @update:value="dataForm.switch_tp.is_white_list = !$event"/>

                    <rb-select
                        largeHelp
                        class="w-3/12 px-2"
                        :label="bots.localization['bots/va/settings/coin_list/select_strategy']"
                        :options="coinListStrategiesOptions"
                        :help="bots.help['coin_list_strategy']"
                        v-model:value="dataForm.switch_tp.coin_list_strategy" />

                    <rb-select
                        v-if="dataForm.switch_tp.coin_list_strategy != -1 && dataForm.switch_tp.coin_list_strategy != 'local'"
                        largeHelp
                        clearable
                        class="w-3/12 pl-2"
                        :label="bots.localization['bots/va/settings/coin_list/shared_coin_list']"
                        :options="sharedCoinListItems"
                        :help="bots.help['shared_coin_list']"
                        v-model:value="dataForm.switch_tp.shared_coin_list" />
                </div>
                <n-divider />
                <!-- coin list selector -->
                <template v-if="canShowSharedCoinList">
                    <div class="flex items-end w-full">
                        <rb-coin-list
                            multiple
                            disabled
                            class="flex-grow"
                            :label="bots.localization['bots/va/settings/coin_list/shared_list_coins']"
                            :dataHelp="bots.help['shared_list_coins']"
                            :value="sharedCoinList"
                            :options="sharedCoinListRef" />
                        <n-button
                            strong
                            class="rounded-md ml-4 text-white/90"
                            :color="gl.mainColor"
                            @click="router.push({name: 'tools.va', query: { tab: 'rbpsCoinList' }})">
                            {{ bots.localization['bots/va/settings/coin_list/create_update_coin_list'] }}
                        </n-button>
                    </div>
                    <n-divider />
                </template>
                <div v-if="dataForm.switch_tp.coin_list_strategy != 'shared' && dataForm.switch_tp.coin_list_strategy != -1">
                    <rb-coin-list
                        multiple
                        :label="bots.localization['bots/va/settings/coin_list/local_list_coins']"
                        v-model:value="dataForm.switch_tp.bw_list"
                        :options="symbolsForMarketRefsOptions" />
                    <n-divider />
                </div>
            </template>

            <!-- period -->
            <div class="flex">
                <div class="w-4/12 pr-2 flex">
                    <rb-input
                        class="w-6/12 pr-2"
                        :label="bots.localization['bot_auto_switch_type_volatility_analyzer_period']"
                        :help="bots.help['switch_period']"
                        :status="errorsField?.period_value ? 'error' : ''"
                        :msg="errorsField?.period_value?.msg"
                        v-model:value="dataForm.switch_tp.period_value"
                        @update:value="delete errorsField?.period_value" />
                    <rb-select
                        largeHelp
                        placeholder="☹"
                        class="w-6/12 pl-2 mt-[30px]"
                        :options="periodUnitOptions"
                        :value="dataForm.switch_tp.period_unit + ''"
                        @update:value="dataForm.switch_tp.period_unit = $event" />
                </div>

                <div class="w-4/12 pl-2 flex">
                    <rb-input
                        class="w-6/12 pr-2"
                        :label="bots.localization['bot_auto_switch_type_volatility_analyzer_interval']"
                        :help="bots.help['switch_interval']"
                        :status="errorsField?.period_interval ? 'error' : ''"
                        :msg="errorsField?.period_interval?.msg"
                        v-model:value="dataForm.switch_tp.interval_value"
                        @update:value="delete errorsField?.period_interval" />
                    <rb-select
                        largeHelp
                        placeholder="☹"
                        class="w-6/12 pl-2 mt-[30px]"
                        :options="intervalUnitsOptions"
                        :value="dataForm.switch_tp.interval_unit + ''"
                        @update:value="dataForm.switch_tp.interval_unit = $event" />
                </div>
            </div>
            <n-divider />

            <div class="flex items-stretch mt-4">
                <rb-input
                    class="w-3/12 pr-1"
                    :nowrap="false"
                    :label="bots.localization['bot_auto_switch_type_volatility_analyzer_rate_change_f']"
                    :help="bots.help['switch_rate_change']"
                    :status="errorsField?.rate_change || bots.errorsForm?.rate_change ? 'error' : ''"
                    :msg="errorsField?.rate_change?.msg || bots.errorsForm?.rate_change?.msg"
                    v-model:value="dataForm.switch_tp.rate_change"
                    @update:value="delete bots.errorsForm?.rate_change, delete errorsField?.rate_change" />
                <rb-input
                    class="w-3/12 px-1"
                    :nowrap="false"
                    :label="bots.localization['bot_auto_switch_type_volatility_analyzer_min_rate_change_count_f']"
                    :help="bots.help['switch_min_rate_change_count']"
                    :status="errorsField?.min_rate_changes_count || bots.errorsForm?.min_rate_changes_count ? 'error' : ''"
                    :msg="errorsField?.min_rate_changes_count?.msg || bots.errorsForm?.min_rate_changes_count?.msg"
                    v-model:value="dataForm.switch_tp.min_rate_changes_count"
                    @update:value="delete bots.errorsForm?.min_rate_changes_count, delete errorsField?.min_rate_changes_count" />
                <rb-input
                    class="w-3/12 px-1"
                    :nowrap="false"
                    :label="bots.localization['bot_auto_switch_type_volatility_analyzer_min_trade_volume_f']"
                    :help="bots.help['switch_min_trade_volume']"
                    :status="errorsField?.min_trade_volume || bots.errorsForm?.min_trade_volume ? 'error' : ''"
                    :msg="errorsField?.min_trade_volume?.msg || bots.errorsForm?.min_trade_volume?.msg"
                    v-model:value="dataForm.switch_tp.min_trade_volume"
                    @update:value="delete bots.errorsForm?.min_trade_volume, delete errorsField?.min_trade_volume" />
                <rb-input
                    class="w-3/12 pl-1"
                    :nowrap="false"
                    :label="bots.localization['bot_auto_switch_type_volatility_analyzer_min_price_f']"
                    :help="bots.help['switch_min_price']"
                    :status="errorsField?.min_price || bots.errorsForm?.min_price ? 'error' : ''"
                    :msg="errorsField?.min_price?.msg || bots.errorsForm?.min_price?.msg"
                    v-model:value="dataForm.switch_tp.min_price"
                    @update:value="delete bots.errorsForm?.min_price, delete errorsField?.min_price" />
            </div>
            <n-divider />

            <!-- PUMP/DUMP filters -->
            <section>
                <div class="flex mb-6">
                    <div class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap">
                        {{ bots.localization['bot_auto_switch_type_volatility_analyzer_pump_dump_filters_title'] }}
                    </div>
                    <n-popover
                        trigger="hover"
                        class="max-w-[500px] rounded-md bg-main"
                        placement="top">
                            <template #trigger>
                                <n-icon class="text-main cursor-pointer text-xl mx-2">
                                    <QuestionCircle20Filled />
                                </n-icon>
                            </template>
                        <span class="text-xs text-white" v-html="bots.help['switch_pd_filters']"></span>
                    </n-popover>
                </div>

                <div class="flex flex-wrap">
                    <div
                        v-for="(pd_filter, i) in dataForm.switch_tp.pd_filters" :key="i"
                        class="mb-4 w-4/12 filters"
                        >
                        <n-card
                            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg relative"
                            :segmented="{
                                content: true,
                                footer: 'soft'
                            }">
                            <n-icon
                                class="text-red-500 text-2xl ml-4 cursor-pointer absolute top-2 right-2"
                                @click="onDeletePDFilter(i)">
                                <DeleteDismiss24Regular />
                            </n-icon>
                            
                            <div class="flex mt-4">
                                <rb-input
                                    class="w-6/12 pr-1"
                                    type="pd_filter"
                                    :label="bots.localization['bot_auto_switch_type_volatility_analyzer_pump_dump_filter_period']"
                                    :help="bots.help['switch_pd_period']"
                                    v-model:value="dataForm.switch_tp.pd_filters[i].pd_period"
                                    @update:value="vaResultsFetched = null" />
                                <rb-input
                                    class="w-6/12 pl-1"
                                    :label="bots.localization['bot_auto_switch_type_volatility_analyzer_rate_change_f']"
                                    :help="bots.help['switch_pd_rate_change']"
                                    v-model:value="dataForm.switch_tp.pd_filters[i].pd_rate_change"
                                    @update:value="vaResultsFetched = null" />
                            </div>
                        </n-card>
                    </div>
                    <div class="mb-4 w-4/12 min-h-[120px] filters">
                        <n-button
                            strong
                            secondary
                            size="large"
                            class="h-full w-full rounded-md"
                            @click="onAddPDFilter">
                            {{ bots.localization['bot_auto_switch_type_volatility_analyzer_pump_dump_filters_add_filter_button'] }}
                        </n-button>
                    </div>
                </div>
            </section>
            <n-divider />

            <div v-if="!vaResultsFetched" class="flex justify-center">
                <n-button
                    strong
                    class="rounded-md text-white/90 mb-3"
                    :color="gl.mainColor"
                    @click="onRunPreset">
                    {{ bots.localization['bot_auto_switch_type_volatility_analyzer_run_analyzer_button'] }}
                </n-button>
            </div>
            <div v-else class="flex justify-center items-center">
                <n-button
                    strong
                    class="rounded-md text-white/90"
                    :color="gl.mainColor"
                    @click="continueSavingPreset = true">
                    {{ bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_title'] }}
                </n-button>
                <n-popover
                    trigger="hover"
                    class="max-w-[500px] rounded-md bg-yellow-600"
                    placement="top">
                    <template #trigger>
                        <n-icon
                            class="cursor-pointer text-lg ml-2 text-yellow-600">
                            <Warning24Filled />
                        </n-icon>
                    </template>
                    <span class="notes text-xs text-white" v-html="bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_info']"></span>
                </n-popover>
            </div>

            <super-table
                v-if="vaResultsFetched"
                columnsStorageName="__columns_bots_create_vaResultsFetched"
                :refresh="false"
                :showFooter="false"
                :columns="vaResultsFetched.columns"
                :records="vaResultsFetched.records">
                <template #tableTitle>
                    <div class="text-sm text-left mb-2">
                        {{ bots.localization['bot_auto_switch_type_volatility_analyzer_results_table_title'] }}
                    </div>
                </template>
            </super-table>
        </n-tab-pane>

        <n-tab-pane name="rbpsPresets" :tab="bots.localization['bot_auto_switch_volatility_analyzer_presets_tab_title']">
            <rb-presets
                :title="bots.localization['bot_auto_switch_volatility_analyzer_presets_info']"
                :presets="refsVaPresetsOk"
                :secondPresets="refsVaPresetsZero"
                :currPresetId="currPresetId"
                @presetChanged="presetChanged">
                <div>
                    <n-divider />
                    <div class="flex justify-center my-4">
                        <n-button
                            strong
                            class="mx-2 rounded-md text-white/90"
                            :disabled="!currPresetId"
                            :color="gl.mainColor"
                            @click="onApplyPreset">
                            {{ bots.localization['bot_auto_switch_volatility_analyzer_presets_apply_presets_button'] }}
                        </n-button>
                        <n-button
                            strong
                            class="mx-2 rounded-md text-white/90"
                            :disabled="!currPresetId"
                            :color="gl.mainColor"
                            @click="onRunProfilePreset">
                            {{ bots.localization['bot_auto_switch_volatility_analyzer_presets_run_analyzer_button'] }}
                        </n-button>
                    </div>
                </div>
            </rb-presets>

            <super-table
                v-if="vpResultsFetched"
                columnsStorageName="__columns_bots_create_vpResultsFetched"
                :refresh="false"
                :showFooter="false"
                :columns="vpResultsFetched.columns"
                :records="vpResultsFetched.records">
                <template #tableTitle>
                    <div class="text-sm text-left mb-2">
                        {{ bots.localization['bot_auto_switch_type_volatility_analyzer_results_table_title'] }}
                    </div>
                </template>
            </super-table>
        </n-tab-pane>
    </n-tabs>
    <base-modal-desktop
        v-model:show="continueSavingPreset">
        <template #default>
            <rb-input
                :label="bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_name_f']"
                :status="errorsPresetField?.name ? 'error' : ''"
                :msg="errorsPresetField?.name?.msg"
                v-model:value="dataForm.switch_tp.title" />
            <rb-input
                class="mt-4"
                typeField="textarea"
                label="Preset Comment"
                v-model:value="dataForm.switch_tp.description" />
        </template>
        <template #footer>
            <div class="flex justify-end">
                <n-button
                    strong
                    class="mt-4 rounded-md text-white/90"
                    :color="gl.mainColor"
                    :loading="continueSavingPresetLoading"
                    :disabled="continueSavingPresetLoading"
                    @click="onSavePreset">
                    {{ bots.localization['bot_settings_save_modal_save_button'] }}
                </n-button>
            </div>
        </template>
    </base-modal-desktop>
</div>
</template>

<script>
// general
import general from './general';

// icons
import { Dismiss16Filled, QuestionCircle20Filled, DeleteDismiss24Regular, Warning24Filled } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input';
import RbSwitch from '@components/rb-switch';
import RbSelect from '@components/rb-select';
import BotPresets from '@components/bots/childs/bot-presets';
import RbCoinList from '@components/rb-coin-list';
import SuperTable from '@components/super-table/desktop.vue';
import RbPresets from '@components/rb-presets';

// naive-ui
import {
    NTabs,
    NIcon,
    NCard,
    NButton,
    NTabPane,
    NPopover,
    NDivider,
    NCheckbox } from 'naive-ui';

export default {
    name: 'bot-pair-switch',
    props: {
        symbolsForMarketRefs: {
            type: Object,
            required: true,
        },
    },
    components: {
        NTabs,
        NIcon,
        NCard,
        RbCoin,
        NButton,
        RbInput,
        RbSwitch,
        NTabPane,
        NDivider,
        NPopover,
        RbSelect,
        NCheckbox,
        RbPresets,
        RbCoinList,
        BotPresets,
        SuperTable,
        Warning24Filled,
        Dismiss16Filled,
        DeleteDismiss24Regular,
        QuestionCircle20Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
.filters {
    &:nth-child(3n + 2) {
        padding: 0 8px;
    }

    &:nth-child(3n + 1) {
        padding-right: 8px;
    }

    &:nth-child(3n + 3) {
        padding-left: 8px;
    }
}
</style>