// vue
import { ref, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRouter } from 'vue-router';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';
import { formatUnexistedCurrsAsUnknown } from '@shared/util';
import { VA_PD_FILTER_FACTORY } from '@shared/factories';

// services
import ToolsService from '@services/tools';
import MarketplaceService from '@services/marketplace';
import fromExponential from 'from-exponential';

// naive-ui
import { useMessage } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const bots = useBots();
    const refs = useRefs();

    // i18n
    const { t } = useI18n();

    // router
    const router = useRouter();

    // naive-ui
    

    // vars
    const dataForm = bots.dataForm;
    const { _ } = window;
    const sharedCoinList = ref();
    const vaResultsFetched = ref();
    const vpResultsFetched = ref();
    const sharedCoinListRef = ref();
    const availableSignalsRef = ref();
    const continueSavingPreset = ref(false);
    const continueSavingPresetLoading = ref(false);
    const errorsField = ref();
    const errorsPresetField = ref();
    const currPresetId = ref();
    const currTab = ref('rbpsSettings');

    watch(() => dataForm.switch_tp.shared_coin_list, async v => {
        if (v === null) {
            sharedCoinList.value = null;
            dataForm.switch_tp.shared_coin_list = -1;
            return;
        }

        if (v != -1)
            await fetchSharedCoinListRef(v);
    });

    watch(() => dataForm.exchange.value, async v => {
        if (!v || v == -1) return;

        if (switchType.value === 'signal')
            await fetchAvailableSignalsRef();
    });

    watch([ ...Object.keys(dataForm.switch_tp)
        .filter(key => key !== 'title' && key !== 'description')
        .map(key => () => dataForm.switch_tp[key]) ], () => {
        vaResultsFetched.value = null;
    });

    // computeds
    const switchType = computed(() => dataForm.switch_tp.type);
    const refsVaPresetsOk = computed(() => _.pickBy(bots.vaPresets, i => i?.ref_id != 0));
    const refsVaPresetsZero = computed(() => _.pickBy(bots.vaPresets, i => i?.ref_id == 0));
    const symbolsForMarketRefsReady = computed(() => props.symbolsForMarketRefs ? Object.keys(props.symbolsForMarketRefs).length > 0 : false);
    const canShowSharedCoinList = computed(() => ['shared', 'local_and_shared'].includes(dataForm.switch_tp.coin_list_strategy) && dataForm.switch_tp.shared_coin_list != -1);
    const useBwList = computed(() => dataForm.switch_tp.use_bw_list);
    const sharedCoinListItems = computed(() => {
        if (dataForm.exchange.value == -1) return [];

        return Object.values(bots.coinLists)
            .filter(cl => cl.exchange == dataForm.exchange.value)
            .map(el => ({
                label: el.title,
                value: el.id,
                ...el,
            }));
    });
    const coinListStrategiesOptions = computed(() => bots.coinListStrategies.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));
    const symbolsForMarketRefsOptions = computed(() => Object.values(props.symbolsForMarketRefs).map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));
    const periodUnitOptions = computed(() => bots.periodUnits.map(el => ({
        label: el.title,
        value: el.id + '',
        ...el,
    })));
    const intervalUnitsOptions = computed(() => bots.intervalUnits.map(el => ({
        label: el.title,
        value: el.id + '',
        ...el,
    })));
  
    const currency = icon => refs.currencies.find(el => el.id === icon);
    const exchange = exchange => refs.exchanges.find(el => el.id === exchange);

    const fetchSharedCoinListRef = async (id) => {
        gl.showLoading = true;

        try {
            var data = await ToolsService.getCoinListInfo(id);
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }

        if (!data?.data.status) {
            gl.showNotification({
                type: 'error',
                msg: data.data?.message,
            });
        } else {
            sharedCoinList.value = data.data.coin_list.coins;
            sharedCoinListRef.value = formatUnexistedCurrsAsUnknown(refs, data.data.coin_list.coins).map(el => ({
                value: el.id,
                label: el.title,
                ...el,
            }));
        };
      
        gl.showLoading = false;
    };

    const fetchAvailableSignalsRef = async () => {
        gl.showLoading = true;

        const pager = CRUD_PAGER_FACTORY({
            exchanges: [dataForm.exchange.value],
        }, { perPage: '*' });

        try {
            const prepare = await MarketplaceService.getMySignals(pager);

            if (!prepare?.data.status) {
                gl.showNotification({
                    type: 'error',
                    msg: prepare.data?.message,
                });
            } else {
                availableSignalsRef.value = prepare.data.records
                    .filter(s => (s.exchanges || []).find(eId => eId == dataForm.exchange.value))
                    .map(el => ({
                        value: el.id,
                        label: el.name,
                        ...el,
                    }));
            };
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }

        gl.showLoading = false;
    };

    const invalidateForm = () => {
        vaResultsFetched.value = null;
        continueSavingPreset.value = false;
    };

    const onRunPreset = async () => {
        gl.showLoading = true;
        gl.loadingMessage = bots.localization['bot_auto_switch_volatility_analyzer_standby'];

        const pd_filters = [...dataForm.switch_tp.pd_filters].map((filter, i) => ({_formPath: `pd_fitler_${i}`, ...filter}));

        const payload = {
            ...dataForm.switch_tp,
            bw_list: useBwList.value
                ? dataForm.switch_tp.bw_list
                : [],
            pd_filters,
            is_white_list: useBwList.value ? dataForm.switch_tp.is_white_list : false,
            exchange: dataForm.exchange.value,
            market: dataForm.settings.profit_coin,
            shared_coin_list: useBwList.value ? dataForm.switch_tp.shared_coin_list : '-1',
            min_price: fromExponential(_.get(dataForm, 'switch_tp.min_price', 0)),
            _formPath: 'tvaSettings',
            _source: 'bots',
        };

        try {
            var data = await ToolsService.runPreset(payload);

            if (!data?.data.status) {
                errorsField.value = { ...data.data.errors_form.tvaSettings.fields };
            } else {
                gl.showNotification({
                    type: 'success',
                    msg: data?.postMessages[0]?.msg,
                });
                errorsField.value = null;
                vaResultsFetched.value = data.data;

                // setTimeout(() => {
                //     document.querySelector('.botTvaResultsBlock').scrollIntoView();
                // });
              
            };
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }
      
        gl.loadingMessage = '';
        gl.showLoading = false;
    };

    const onSavePreset = async () => {
        continueSavingPresetLoading.value = true;

        const pd_filters = [...dataForm.switch_tp.pd_filters].map((filter, i) => ({_formPath: `pd_fitler_${i}`, ...filter}));
        const payload = {
            ...dataForm.switch_tp,
            bw_list: useBwList.value
                ? dataForm.switch_tp.bw_list
                : [],
            pd_filters,
            is_white_list: useBwList.value ? dataForm.switch_tp.is_white_list : false,
            exchange: dataForm.exchange.value,
            market: dataForm.settings.profit_coin,
            shared_coin_list: useBwList.value ? dataForm.switch_tp.shared_coin_list : '-1',
            min_price: fromExponential(_.get(dataForm, 'switch_tp.min_price', 0)),
            _formPath: 'tvaSettings',
            _source: 'bots',
        };

        try {
            var data = await ToolsService.savePreset(payload);

            if (!data?.data.status) {
                errorsPresetField.value = { ...data.data.errors_form };
            } else {
                const { id, title, description, message: msg } = data.data;
                gl.showNotification({
                    type: 'success',
                    msg,
                });
                errorsPresetField.value = null;
                bots.vaPresets.push({ id, title, description });
                currPresetId.value = id;
                currTab.value = 'rbpsPresets';

                invalidateForm();
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }

        continueSavingPresetLoading.value = false;
    };

    const onApplyPreset = async () => {
        gl.showLoading = true;

        try {
            var data = await ToolsService.getPresetInfo(currPresetId.value);

            if (!data?.data.status) {
                gl.showNotification({
                    type: 'error',
                    msg: data.postMessages[0].msg,
                });
            } else {
                vaResultsFetched.value = null;

                let { preset } = data.data;
                delete preset.id;
                preset.exchange = dataForm.exchange.value;
                preset.market = dataForm.settings.profit_coin;

                dataForm.switch_tp = { ...dataForm.switch_tp, ...preset };
                currTab.value = 'rbpsSettings';
            };
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }

        gl.showLoading = false;
    };

    const onRunProfilePreset = async () => {
        gl.showLoading = true;
        gl.loadingMessage = bots.localization['bot_auto_switch_volatility_analyzer_standby'];

        const payload = { id: currPresetId.value, _source: 'bots' };

        try {
            var data = await ToolsService.runPreset(payload);

            if (!data?.data.status) {
                gl.showNotification({
                    type: 'error',
                    msg: data?.postMessages[0]?.msg,
                });
            } else {
                gl.showNotification({
                    type: 'success',
                    msg: data?.postMessages[0]?.msg,
                });
                vpResultsFetched.value = data.data;
              
                // setTimeout(() => {
                //     document.querySelector('.botTvaResultsBlock2').scrollIntoView();
                // });
            };
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }
      
        gl.loadingMessage = '';
        gl.showLoading = false;
    };

    const presetChanged = $event => {
        currPresetId.value = $event;
    };

    const onDeletePDFilter = i => {
        dataForm.switch_tp.pd_filters.splice(i, 1);
    };

    const updatePDFilter = ([ $event, i, field ]) => {
        dataForm.switch_tp.pd_filters[i][field] = +$event;
        vaResultsFetched.value = null;
    };

    const onAddPDFilter = () => {
        dataForm.switch_tp.pd_filters.push(VA_PD_FILTER_FACTORY());
        invalidateForm();
    };

    onMounted(() => {
        const id = dataForm.switch_tp.shared_coin_list;

        if (id != -1)
            fetchSharedCoinListRef(id);

        if (switchType.value === 'signal')
            fetchAvailableSignalsRef();
    });

    return {
        gl,
        bots,
        router,
        currTab,
        dataForm,
        errorsField,
        currPresetId,
        sharedCoinList,
        refsVaPresetsOk,
        vpResultsFetched,
        vaResultsFetched,
        sharedCoinListRef,
        refsVaPresetsZero,
        periodUnitOptions,
        errorsPresetField,
        sharedCoinListItems,
        intervalUnitsOptions,
        continueSavingPreset,
        canShowSharedCoinList,
        coinListStrategiesOptions,
        symbolsForMarketRefsReady,
        symbolsForMarketRefsOptions,
        continueSavingPresetLoading,
        currency,
        exchange,
        onRunPreset,
        onSavePreset,
        presetChanged,
        onAddPDFilter,
        onApplyPreset,
        updatePDFilter,
        invalidateForm,
        onDeletePDFilter,
        onRunProfilePreset,
    };
}