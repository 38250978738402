<template>
<section>
    <div class="flex items-center">
        <div class="text-xs text-gray-600 dark:text-white/75 whitespace-nowrap">{{ data.title }}</div>
        <n-popover
            v-if="data.dataHelp"
            style="width: 300px" trigger="hover" placement="top"
            class="rounded-md bg-main">
            <template #trigger>
                <n-icon
                    class="cursor-pointer text-xl mx-2 text-main">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span
                class="notes text-xs text-white"
                v-html="data.dataHelp">
            </span>
        </n-popover>
    </div>
    <div class="flex mt-2">
        <rb-wallet-select-mobile
            :value="modelValue !== -1 ? modelValue : null"
            @update:value="$emit('update:modelValue', $event)"
            class="flex-grow"
            :items="options"/>
        <n-button
            strong
            class="rounded-md text-white/90 ml-2"
            :color="gl.mainColor"
            :disabled="data.loading || data.buttonCheckDisabled || checkBalanceLoading"
            :loading="data.loading || checkBalanceLoading"
            @click="onCheckBalance">
            {{ data.buttonCheckLabel }}
        </n-button>
    </div>
    <div v-if="status === 'error'" class="w-full mt-1 text-red-400 text-right text-[10px]">{{ msg }}</div>
    <section class="flex justify-end mt-4">
        <n-button
            strong
            secondary
            size="tiny"
            class="rounded-md mr-1 flex-grow"
            @click="showCreateWalletModal = true, walletId = null">
            + {{ data.buttonCreateLabel }}
        </n-button>
        <n-button
            strong
            secondary
            size="tiny"
            class="rounded-md ml-1 flex-grow"
            :disabled="data.buttonEditDisabled"
            @click="showCreateWalletModal = true, walletId = modelValue">
            <template #icon>
                <n-icon
                    class="cursor-pointer text-base mx-2">
                    <Edit16Regular />
                </n-icon>
            </template>
            {{ data.buttonEditLabel }}
        </n-button>
    </section>
    <div v-if="symbolBalance || symbolTotalBalance" class="w-full flex justify-between mt-6">
        <n-statistic
            v-if="symbolBalance"
            tabular-nums
            class="text-left"
            :label="bots.localization['bots_new_wallet_checkbalance_available']">
            <n-number-animation
                :from="0"
                :to="+symbolBalance"
                :precision="8"
            />
        </n-statistic>
        <n-statistic
            v-if="symbolTotalBalance"
            tabular-nums
            class="text-left"
            :label="bots.localization['bots_new_wallet_checkbalance_total']">
            <n-number-animation
                :from="0"
                :to="+symbolTotalBalance"
                :precision="8"
            />
        </n-statistic>
    </div>
    <rb-wallet-cr
        :wallet-id="walletId"
        :show="showCreateWalletModal"
        :exchange-id="exchangeRef"
        :coin-symbol-for-wallet="coinSymbolForWallet"
        :can-create-new-api-key="false"
        @update:show="showCreateWalletModal = $event"
        @updateWallet="$emit('updateWallet', $event)"
        @addNewWallet="$emit('addNewWallet', $event)"
        @addNewApiKey="$emit('addNewApiKey', $event)" />
</section>
</template>

<script>
// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@/store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// services
import WalletsService from '@services/walletsService';

// components
import RbCoin from '@components/rb-coin';
import RbWalletCr from '@components/rb-wallet-cr';
import RbSelect from '@components/rb-select/mobile.vue';
import RbWalletSelectMobile from '@components/rb-wallet-select/mobile.vue';

// icons
import { Dismiss16Filled, QuestionCircle20Filled, Warning24Filled, Edit16Regular } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NInput,
    NModal,
    NSelect,
    NButton,
    NDivider,
    NPopover,
    NStatistic,
    NNumberAnimation } from 'naive-ui';

export default {
    name: 'bot-wallet-mobile',
    emits: ['update:modelValue', 'walletReady'],
    props: {
        apiKey: [String, Number],
        modelValue: [String, Number],
        exchangeRef: [String, Number],
        coinSymbolForWallet: String,
        data: {
            type: Object,
            required: true,
        },
        exchangeInfoRef: {
            type: Object,
            required: true,
        },
        pairWalletsRefs: {
            type: Array,
            required: true,
        },
        walletsRefs: {
            type: Array,
            required: true,
        },
        apiKeysRefs: {
            type: Array,
            required: true,
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        NModal,
        RbCoin,
        NInput,
        NButton,
        NSelect,
        NPopover,
        NDivider,
        RbSelect,
        NStatistic,
        RbWalletCr,
        Edit16Regular,
        Warning24Filled,
        Dismiss16Filled,
        NNumberAnimation,
        RbWalletSelectMobile,
        QuestionCircle20Filled,
    },
    setup(props) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const bots = useBots();

        // vars
        const balancesRefs = ref();
        const showCreateWalletModal = ref(false);
        const checkBalanceLoading = ref(false);

        //naive-ui
        

        const options = computed(() => props.pairWalletsRefs?.length ? props.pairWalletsRefs.map(el => ({
            value: el.id,
            label: el.name,
            ...el,
        })) : []);

        // const pairWalletsRefs = computed(() => {
        //     if (!walletsRefs.value || bot.value.data.general.algo == -1) return [];
        //     const symbolForWallet = coinSymbolForWallet.value;
        //     return walletsRefs.value.filter(w => w.currency == symbolForWallet);
        // });

        const symbolBalance = computed(() => balancesRefs.value?.[props.coinSymbolForWallet] || '');
        const symbolTotalBalance = computed(() => balancesRefs.value?.[`${props.coinSymbolForWallet}_total`] || '');

        const currenciesRefs = computed(() => props.exchangeInfoRef?.currencies || []);

        watch(() => props.apiKey, () => {
            balancesRefs.value = null;
        });

        watch(() => props.modelValue, () => {
            balancesRefs.value = null;
        });

        const renderTag = ({ option }) => {
            return h('div', {
                class: 'w-full flex flex-row items-center',
            }, [
                h('span', {
                    class: 'text-sm flex-grow',
                }, option.name),
                h('span', {
                    class: 'text-sm text-main font-semibold',
                }, option.amount),
                h(RbCoin, {
                    coin: { ...option, code_name: 'rb-cc__' + option.currency },
                    class: 'ml-4 w-4',
                }),
                h('span', {
                    class: 'uppercase ml-2 opacity-50',
                }, option.currency),
            ]);
        };

        const renderLabel = option => {
            return h('div', {
                class: 'w-full py-1 flex flex-row items-center',
            }, [
                h('span', {
                    class: 'text-xs flex-grow',
                }, option.name),
                h('span', {
                    class: 'text-sm font-semibold',
                }, option.amount),
                h(RbCoin, {
                    coin: { ...option, code_name: 'rb-cc__' + option.currency },
                    class: 'ml-2 w-4',
                }),
                h('span', {
                    class: 'uppercase ml-2 mr-8 opacity-50',
                }, option.currency ),
            ]);
        };

        const exchange = (exchange) => {
            return refs.exchanges.find(el => el.id === exchange);
        };

        const currency = (icon) => {
            return refs.currencies.find(el => el.id === icon);
        };

        const onCheckBalance = async () => {
            checkBalanceLoading.value = true;

            const prepare = await WalletsService.getBalances(props.apiKey);

            if (!prepare.data.status) {
                prepare.data.errors.forEach(({ msg }) => {
                    gl.showNotification({
                        type: 'error',
                        msg,
                    });
                });

                // reset balancesRefs
                balancesRefs.value = null;
            } else {
                balancesRefs.value = prepare.data.balances;
            }

            checkBalanceLoading.value = false;
        };

        onMounted(() => {
            // console.log('pairWalletsRefs', props.pairWalletsRefs);
        });

        return {
            gl,
            bots,
            options,
            symbolBalance,
            currenciesRefs,
            symbolTotalBalance,
            checkBalanceLoading,
            showCreateWalletModal,
            exchange,
            renderTag,
            renderLabel,
            onCheckBalance,
        };
    },
};
</script>