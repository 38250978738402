<template>
<div>
  <div class="flex justify-between items-center">
    <n-button
        strong
        class="rounded-md text-white/90"
        :color="gl.mainColor"
        :disabled="disabled"
        @click.prevent="onDec" >
        <strong class="text-lg">-</strong>
    </n-button>

    <div class="w-[350px] flex items-center">
        <n-input
            type="text"
            class="flex-grow mx-2 text-center font-bold rounded-md"
            :placeholder="''"
            :disabled="disabled"
            :allow-input="onlyAllowNumber"
            :value="modelValue"
            @keyup="onKeyUp"
            @input="onInput"
            @blur="onBlur"/>
    </div>
    
    <n-button
        strong
        class="rounded-md text-white/90"
        :color="gl.mainColor"
        :disabled="disabled"
        @click.prevent="onInc" >
        <strong class="text-lg">+</strong>
    </n-button>
  </div>
  <n-slider
    class="w-full"
    :step="1"
    :value="modelValue"
    :min="minMax.min"
    :max="minMax.max"
    :marks="sliderMarks"
    :disabled="disabled"
    :format-tooltip="formatTooltip"
    @update:value="onInput"/>
</div>
</template>

<script>
// vue
import { computed, watch, onMounted } from 'vue';

// naive-ui
import {
    NInput,
    NSlider,
    NButton } from 'naive-ui';

// store
import { useGl } from '@store/ts/gl';

export default {
    name: 'RbMatrixCount',
    emits: ['update:modelValue'],
    props: {
        label: String,
        disabled: Boolean,
        interval: Boolean,
        modelValue: {
            type: Number,
            default: 0,
        },
        min: {
            default: 1,
            type: [String, Number],
        },
        max: {
            type: [String, Number],
        },
    },
    components: {
        NInput,
        NSlider,
        NButton,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();

        watch(() => props.max, (newV, oldV) => {
            if (newV < oldV && newV <= props.modelValue)
                emit('update:modelValue', newV);
        });

        const minMax = computed(() => {
            const min = parseInt(props.min, 10);
            const max = parseInt(props.max, 10);
            return { min, max };
        });

        const onInc = () => {
            const { min, max } = minMax.value;
            emit('update:modelValue', Math.min(Math.max(props.modelValue + 1, min), max));
        };

        const onDec = () => {
            const { min, max } = minMax.value;
            emit('update:modelValue',  Math.max(Math.min(props.modelValue - 1, max), min));
        };

        const onKeyUp = ($event) => {
            if ($event.keyCode === 38) onInc();
            else if ($event.keyCode === 40) onDec();
        };

        const onInput = ($event) => {
            if ($event == props.modelValue) return;

            const { min, max } = minMax.value;
            let leverageValue = parseInt($event, 10);

            if (!isFinite(leverageValue) || String($event) !== String(leverageValue))
                return void emit('update:modelValue', $event);
              
            leverageValue = leverageValue < min ? min : (leverageValue > max ? max : leverageValue);

            emit('update:modelValue',  leverageValue);
        };

        const onBlur = () => {
            const { min } = minMax.value;

            if (!props.modelValue)
                emit('update:modelValue', min);
        };

        const sliderMarks = computed(() => {
            const marks = {};

            for (let i = minMax.value.min; i <= minMax.value.max; i += Math.ceil(minMax.value.max / 6))
                marks[i] = i;

            marks[minMax.value.max] = minMax.value.max;
            

            return marks;
            
        });

        const formatTooltip = (value) => value;

        onMounted(() => {
            onBlur();
        });

        return {
            gl,
            minMax,
            sliderMarks,
            onInc,
            onDec,
            onBlur,
            onKeyUp,
            onInput,
            formatTooltip,
            onlyAllowNumber: v => !v || /^\d+$/.test(v),
        };
    },
};
</script>`