const { _ } = window;

const GRID_ACTIONS = {
    ACTION_NEW: 'ACTION_NEW',
    ACTION_EDIT: 'ACTION_EDIT',
    ACTION_CLONE: 'ACTION_CLONE',
};

const prepareStartFilters = payload => {
    return (_.get(payload, 'start_filters', [])).map((sf, i) => ({...sf, pair: sf.pair == -1 || !sf.pair ? null : sf.pair, _formPath: `sf_${i}`}));
};

const prepareSwitchTp = payload => {
    const useBwList = !!payload.switch_tp.use_bw_list;
    const bwList = payload.switch_tp.bw_list;
    const sharedCoinList = payload.switch_tp.shared_coin_list;

    return {
        ...payload.switch_tp,
        pd_filters: (_.get(payload, 'switch_tp.pd_filters', [])).map((filter, i) => ({_formPath: `pd_fitler_${i}`, ...filter})),
        bw_list: useBwList ? bwList : [],
        is_white_list: useBwList ? payload.switch_tp.is_white_list : false,
        shared_coin_list: useBwList ? sharedCoinList : '-1',
    };
};

export {
    GRID_ACTIONS,
    prepareSwitchTp,
    prepareStartFilters,
};