<template>
<section>
    <div class="mt-4 w-6/12">
      <div class="flex justify-between items-start">
        <div class="w-2/12 flex justify-between mt-2">
          <div class="text-base whitespace-nowrap">{{ data.title }}</div>
          <n-popover trigger="hover" placement="right">
            <template #trigger>
              <n-icon class="text-main cursor-pointer text-2xl mx-4">
                <QuestionCircle20Filled />
              </n-icon>
            </template>
            <span v-html="data.dataHelp"></span>
          </n-popover>
        </div>

        <div class="w-10/12">
          <div v-if="exchangeMeta2Info" class="notes mb-2" v-html="exchangeMeta2Info"></div>
          <div class="flex justify-between">
            <n-select
              filterable
              size="large"
              :options="options"
              :placeholder="data.placeholder"
              :loading="data.loading"
              :disabled="data.loading"
              :status="status"
              :value="modelValue !== -1 ? modelValue : undefined"
              @update:value="$emit('update:modelValue', $event)" />
            <n-button
              strong
              size="large"
              class="ml-4"
              :color="gl.mainColor"
              :disabled="data.loading"
              :loading="data.loading"
              @click="showModal = true">
              + {{ data.buttonLabel }}
            </n-button>
            <div v-if="exchangeMetaInfo" class="notes mb-2" v-html="exchangeMetaInfo"></div>
          </div>
          <div v-if="status === 'error'" class="w-full mt-2 text-red-400 text-left">{{ msg }}</div>
        </div>
      </div>
    </div>
    <n-divider v-if="divider" />

    <n-modal
      v-model:show="showModal"
      transform-origin="center">
      <n-card
        style="width: 800px"
        size="small"
        role="dialog"
        aria-modal="true"
        :segmented="{
          content: true,
          footer: 'soft'
        }">
        <template #header>
          <div class="flex flex-row items-center justify-between">
            <span
              class="inline-block rb-td-stock-icon ml-4"
              :class="`rb-td-stock-icon-${exchange(exchangeRef)?.code_name}`" >
            </span>
            <n-button
              strong
              circle
              :color="gl.mainColor"
              @click="showModal = false">
              <template #icon>
                <n-icon color="#fff" size="20"><Dismiss16Filled /></n-icon>
              </template>
            </n-button>
          </div>
        </template>
        <template #default>
          <div v-if="exchangeMeta2Info" class="notes mb-2" v-html="exchangeMeta2Info"></div>

          <section class="mt-8">
            <label
              v-for="(item, p, i) in apiKeyModel"
              :key="i"
              class="mt-4 block">
              <div class="flex justify-between items-start">
                <div class="w-3/12 text-base mt-2">{{ item.title }}</div>

                <n-input
                  clearable
                  class="w-9/12"
                  type="text"
                  size="large"
                  v-model:value="item.value"
                  :status="item.status"
                  :placeholder="item.placeholder"/>
              </div>
              <div v-if="item.status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ item.msg }}</div>
              <div v-if="item.info" class="notes mb-2" v-html="item.info"></div>
              <n-divider v-if="i < Object.keys(apiKeyModel).length - 1" />
            </label>
          </section>
        </template>
        <template #footer>
          <div class="flex justify-end">
            <n-button
              strong
              size="large"
              :color="gl.mainColor"
              :disabled="loading"
              :loading="loading"
              @click="onAddApiKey">
              {{ bots.localization['bots_new_apikey_add_button'] }}
            </n-button>
          </div>
        </template>
      </n-card>
    </n-modal>
</section>
</template>

<script>
// vue
import { ref, reactive, computed } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@/store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// services
import ApiKeysService from '@services/apiKeysService';

// icons
import { Dismiss16Filled, QuestionCircle20Filled } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NCard,
    NInput,
    NModal,
    NSelect,
    NButton,
    NDivider,
    NPopover,
    NCheckbox,
    useMessage } from 'naive-ui';

export default {
    name: 'BotApiKey',
    emits: ['update:modelValue'],
    props: {
        modelValue: [String, Number],
        exchangeRef: [String, Number],
        data: {
            type: Object,
            required: true,
        },
        apiKeysRefs: {
            type: Array,
            required: true,
        },
        divider: {
            type: Boolean,
            default: false,
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
    },
    components: {
        NIcon,
        NCard,
        NModal,
        NInput,
        NButton,
        NSelect,
        NPopover,
        NDivider,
        Dismiss16Filled,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const bots = useBots();

        // vars
        const showModal = ref(false);
        const loading = ref(false);

        // i18n
        const { t } = useI18n();

        // naive-ui
        
        
        const options = computed(() => props.apiKeysRefs?.length ? props.apiKeysRefs.map(el => ({
            value: el.id,
            label: el.name,
            ...el,
        })) : []);

        const exchange = (exchange) => {
            return refs.exchanges.find(el => el.id === exchange);
        };

        const exchangeMetaInfo = computed(() => exchange(props.exchangeRef)?.meta);
        const exchangeMeta2Info = computed(() => exchange(props.exchangeRef)?.meta2);

        const apiKeyModel = reactive({
            name: {
                value: '',
                title: bots.localization['bots_new_apikey_name_f'],
                placeholder: bots.localization['bots_new_apikey_name_i'],
                info: undefined,
                status: undefined,
                msg: undefined,
            },
            key: {
                value: '',
                title: bots.localization['bots_new_apikey_key_f'],
                placeholder: bots.localization['bots_new_apikey_key_i'],
                info: exchangeMetaInfo.value,
                status: undefined,
                msg: undefined,
            },
            sign: {
                value: '',
                title: bots.localization['bots_new_apikey_sign_f'],
                placeholder: bots.localization['bots_new_apikey_sign_i'],
                info: undefined,
                status: undefined,
                msg: undefined,
            },
        });

        const onAddApiKey = async () => {
            loading.value = true;

            const records = {
                exchange: props.exchangeRef,
                name: apiKeyModel.name.value,
                key: apiKeyModel.key.value,
                sign: apiKeyModel.sign.value,
                _formPath: 'CreateApiKeyDialog',
            };

            try {
                const result = await ApiKeysService.addApiKeyToExchange(records.exchange, records);

                if (!result.data.status) {
                    if (result.data?.errors_form) {

                        for (let key in apiKeyModel) {
                            const fields = result.data.errors_form.CreateApiKeyDialog.fields;
                            const el = Object.keys(fields).find(el => el === key);

                            if (el) {
                                apiKeyModel[key].status = 'error';
                                apiKeyModel[key].msg = fields[el].msg;
                            } else {
                                apiKeyModel[key].status = 'success';
                                apiKeyModel[key].msg = undefined;
                            }
                        }
                    }
                } else {
                // clean apiKeyModel
                    for (let key in apiKeyModel)
                        apiKeyModel[key].value = '';

                    // show messages
                    result.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });

                    showModal.value = false;

                    // getApiKeysList
                    emit('getApiKeysList');
                }
            } catch {
                return void gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });
            }

            loading.value = false;
        };

        return {
            gl,
            bots,
            loading,
            options,
            showModal,
            apiKeyModel,
            exchangeMetaInfo,
            exchangeMeta2Info,
            exchange,
            onAddApiKey,
        };
    },
};
</script>

<style lang="scss">
@import '@/css/stock-icon.scss';
</style>