<template>
<div class="flex items-center cursor-pointer" @click="show = !show">
    <div class="text-md text-gray-600 dark:text-white/75 font-semibold">{{ bots.localization['bot_start_filters_title'] }}</div>
    <n-icon class="text-sm ml-2 transition transform text-base" :class="show ? 'rotate-90' : ''">
        <IosArrowRtl24Filled />
    </n-icon>
    <n-button
        v-if="dataForm.start_filters_enabled"
        strong
        secondary
        size="small"
        class="rounded-md ml-4"
        :disabled="!dataForm.start_filters.length"
        @click.prevent.stop="showCreateNewPreset = true">
        {{ bots.localization?.bot_settings_filters_save_button || 'Сохранить шаблон фильтров' }}
    </n-button>
</div>
<n-collapse-transition :show="show">
    <div class="mt-4">
        <section class="flex justify-between items-center">
            <!-- <rb-checkbox
                :label="'- ' + bots.localization['bot_start_filters_enable_checkbox']"
                :helpWarning="dataForm.start_filters_enabled ? bots.localization['bots_bot_start_filters'] : null"
                v-model:checked="dataForm.start_filters_enabled" /> -->
            <a
                :href="whatAreBotStartFiltersLink[refs.lang]"
                class="text-main underline hover:no-underline"
                target="_blank">
                {{ bots.localization['what_are_bot_start_filters'] || 'Что такое фильтры старта бота?' }}
            </a>

            <!-- v-if="dataForm.start_filters_enabled" -->
            <div  class="flex flex-grow justify-end">
                <rb-select
                    label-position="left"
                    :label="(bots.localization?.filters_template_title || 'Шаблоны фильтров') + ':'"
                    :options="filterPresetsOptions"
                    v-model:value="filterId"
                    @update:value="changeFilterPresets" />
                <router-link class="ml-2" :to="{ name: 'tools.presets', params: { tab: 'start-filters-presets' } }" target='_blank'>
                    <n-button
                        secondary
                        class="rounded-md font-semibold w-full">
                        {{ bots.localization?.bot_template_management_label || 'Управление шаблонами' }}
                    </n-button>
                </router-link>
            </div>
        </section>
        <div class="mt-4 flex flex-wrap items-stretch">
            <div
                v-for="(filter, i) in localStartFilters"
                :key="i+'_'+filter.id+'_'+filter.id_op"
                class="mb-4 w-4/12 filters"
                :id="getHTMLid(dataForm.start_filters[filter.index].id)">
                <n-card
                    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg relative h-full"
                    :segmented="{
                        content: true,
                        footer: 'soft'
                    }">
                    <n-icon
                        class="text-red-500 text-2xl ml-4 cursor-pointer absolute top-2 right-2"
                        @click="onDeleteStartFilter(filter.index)">
                        <DeleteDismiss24Regular />
                    </n-icon>
                    
                    <div class="mt-4">
                        <rb-select
                            class="mb-2"
                            :label="bots.localization['bot_start_filters_filter_number'] + ++i"
                            :options="startFiltersIndOptions"
                            :help="filter.help.value"
                            :value="dataForm.start_filters[filter.index].parent_id"
                            @update:value="setMainSelect($event, filter)" />

                        <template v-for="field in filter.fields.value" :key="field.name">
                            <template v-if="field.input_type == 'select'">
                                <rb-select
                                    class="mb-2"
                                    :label="field.title"
                                    :placeholder="field.placeholder"
                                    :options="getOptions(field.options)"
                                    :status="!!bots.innerForms[filter.id]?.fields[field.name]"
                                    :msg="bots.innerForms[filter.id]?.fields[field.name]?.msg"
                                    :value="dataForm.start_filters[filter.index][field.name] || field.value"
                                    @update:value="dataForm.start_filters[filter.index][field.name] = $event" />
                            </template>
                            <template v-if="field.input_type == 'text'">
                                <div v-if="field.min != undefined || field.max != undefined" class="mb-2">
                                    <div
                                        class="text-md dark:text-white/75 whitespace-nowrap label text-left"
                                        :class="!!bots.innerForms[filter.id]?.fields[field.name] ? 'text-red-500' : 'text-gray-600'"
                                        v-html="field.title">
                                    </div>
                                    <n-input-number
                                        class="mt-2"
                                        :update-value-on-input="false"
                                        :value="dataForm.start_filters[filter.index][field.name] || field.value || field.min"
                                        @update:value="dataForm.start_filters[filter.index][field.name] = $event"
                                        :placeholder="field.placeholder"
                                        :status="!!bots.innerForms[filter.id]?.fields[field.name] ? 'error' : undefined"
                                        :min="field.min"
                                        :max="field.max" />
                                    <div class="text-md text-red-400 whitespace-nowrap w-full text-left mt-4">
                                        {{ bots.innerForms[filter.id]?.fields[field.name]?.msg }}
                                    </div>
                                </div>
                                <template v-else>
                                    <rb-input
                                        class="mb-2"
                                        :label="field.title"
                                        :placeholder="field.placeholder"
                                        :status="!!bots.innerForms[filter.id]?.fields[field.name]"
                                        :msg="bots.innerForms[filter.id]?.fields[field.name]?.msg"
                                        :value="dataForm.start_filters[filter.index][field.name] || field.value"
                                        @update:value="dataForm.start_filters[filter.index][field.name] = $event" />
                                </template>
                            </template>
                        </template>

                        <rb-pair-select
                            v-if="startFilterHasPair(filter.id, filter.index)"
                            :options="pairsRefs"
                            :label="bots.localization['pair_f']"
                            :value="dataForm.start_filters[filter.index].pair"
                            :placeholder="bots.localization['bots/start_filters/current_pair/label']"
                            @update:value="updateStartFilters({ i: filter.index, key: 'pair',  value: $event })" />
                    </div>
                </n-card>
            </div>
            <div class="w-full mb-4 flex justify-start">
                <n-button
                    strong
                    secondary
                    class="rounded-md"
                    @click="onAddNewStartFilter">
                    + {{ bots.localization?.add_new_start_filter || 'Add new start filter' }}
                </n-button>
            </div>
        </div>
    </div>
</n-collapse-transition>
<base-modal-desktop
    :label="bots.localization?.bot_settings_filters_save_modal_title || 'Новый шаблон фильтров'"
    v-model:show="showCreateNewPreset">
    <template #default>
        <rb-input
            class="mt-4"
            :label="newPresetModel.title.title"
            :status="newPresetModel.title.status"
            :msg="newPresetModel.title.msg"
            :placeholder="newPresetModel.title.placeholder"
            v-model:value="newPresetModel.title.value"
            @update:value="newPresetModel.title.msg = null, newPresetModel.title.status = null" />
        <rb-input
            class="mt-4"
            type="textarea"
            :label="newPresetModel.description.title"
            :status="newPresetModel.description.status"
            :msg="newPresetModel.description.msg"
            :placeholder="newPresetModel.description.placeholder"
            v-model:value="newPresetModel.description.value" />
    </template>
    <template #footer>
        <div class="flex justify-end">
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :disabled="loading"
                :loading="loading"
                @click="onCreateNewFiltersPreset">
                {{ bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_save_button'] }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NInputNumber,
    NCollapseTransition } from 'naive-ui';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbCheckbox from '@components/rb-checkbox';
import RbPairSelect from '@components/rb-pair-select';

// icons
import { Dismiss16Filled, IosArrowRtl24Filled, DeleteDismiss24Regular } from '@vicons/fluent';

export default {
    name: 'bot-start-filters',
    props: {
        pairsRefs: {
            type: Array,
            required: true,
        },
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        RbInput,
        NButton,
        NDivider,
        RbSelect,
        RbCheckbox,
        NInputNumber,
        RbPairSelect,
        Dismiss16Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
.filters {
    &:nth-child(3n + 2) {
        padding: 0 8px;
    }

    &:nth-child(3n + 1) {
        padding-right: 8px;
    }

    &:nth-child(3n + 3) {
        padding-left: 8px;
    }
}
</style>